import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { Alert, Box, Button, CircularProgress, Grid, useMediaQuery, useTheme } from '@mui/material';
import { FileDownloadDone } from '@mui/icons-material/';
import { CategoryCard, CategoryList, DeliverModal, ResetSchemaModal } from 'pages/SchemaPage';
import {
  AnswerCategory,
  AnswerItem,
  AnswerSubCategory,
  ISchema,
  ISchemaResult,
  QuestionCategory,
  QuestionItem,
  QuestionSubCategory,
} from 'types';
import { getUnfinishedSchemaFromSessionId, putSchema } from 'util/requests';
import { SchemaContext } from 'App';
import Tutorial from '../../components/core/Tutorial';

export const SchemaPage: FunctionComponent<Record<string, never>> = () => {
  document.title = 'Miljøgjennomgang - Skjema';

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { schema, setSchema } = useContext(SchemaContext);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [activeCategoryIndex, setActiveCategoryIndex] = useState<number>(0);
  const [lastViewedQuestionInCategory, setLastViewedQuestionInCategory] = useState<
    {
      subCategoryIndex: number;
      questionIndex: number;
    }[]
  >([]);

  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [data, setData] = useState<ISchemaResult | undefined>(undefined);

  const { mutate: putMutate, status: putStatus } = useMutation('putSchema', (schema: ISchema) =>
    deliverSchema(schema, false),
  );
  const { mutate: deliverMutate, status: deliverStatus } = useMutation(
    'deliverSchema',
    (schema: ISchema) => putSchema(schema, true),
  );

  const deliverSchema = async (schema: ISchema, finished = false) => {
    const schemaSaved: boolean = await putSchema(schema, finished);
    if (schemaSaved) {
      setSavedStatus(true);
    }
  };

  const { name } = useParams<{ name: string }>();

  const debouncedWaitTime = 2000;
  const [debouncedSchema] = useDebounce(schema, debouncedWaitTime);

  const [savedStatus, setSavedStatus] = useState(false);
  const [showSavedAlert, setShowSavedAlert] = useState(false);

  const [openModal, setOpenModal] = useState<'reset' | 'deliver' | 'none'>('none');
  const [openTutorial, setOpenTutorial] = useState(false);
  const handleOpenTutorial = () => {
    setOpenTutorial(true);
  };
  const handleCloseTutorial = () => {
    setOpenTutorial(false);
  };

  const newSchema = (schema: ISchema): ISchema => ({
    name: schema.name,
    questions: schema.questions.map((category: QuestionCategory) => ({
      name: category.name,
      subCategories: category.subCategories.map((subCategory: QuestionSubCategory) => ({
        name: subCategory.name,
        items: subCategory.items.map((question: QuestionItem) => ({
          ...question,
          comment: undefined,
        })),
      })),
    })),
    answers: schema.answers.map((category: AnswerCategory) => ({
      subCategories: category.subCategories.map((subCategory: AnswerSubCategory) => ({
        items: subCategory.items.map((answers: AnswerItem) => ({
          ...answers,
          answer: undefined,
          comment: undefined,
        })),
      })),
    })),
  });

  const ResetSchemaLogic = (schema: ISchema) => {
    //This is not an optimal solution, but we had to make sure the component re-rendered
    //when you already were on the first category. This way you are now sent back to the first category
    /// on the first question regardless of where you were in the schema.
    setLastViewedQuestionInCategory(
      lastViewedQuestionInCategory.map(() => ({
        subCategoryIndex: 1,
        questionIndex: 1,
      })),
    );
    setActiveCategoryIndex(1);

    setLastViewedQuestionInCategory(
      lastViewedQuestionInCategory.map(() => ({
        subCategoryIndex: 0,
        questionIndex: 0,
      })),
    );
    setActiveCategoryIndex(0);

    setSchema(newSchema(schema));
  };

  const tryResetSchema = () => {
    if (!schema) {
      return null;
    }
    putMutate(newSchema(schema));
  };

  const getUnfinishedSchema = async () => {
    setStatus('loading');
    try {
      const schemaResult = await queryClient.fetchQuery('getUnfinishedSchemaFromSessionId', () =>
        getUnfinishedSchemaFromSessionId(name),
      );
      setData(schemaResult);
    } catch (_) {
      setStatus('error');
    }

    setStatus('success');
  };

  useEffect(() => {
    getUnfinishedSchema();
  }, []);

  useEffect(() => {
    if (data) {
      window.sessionStorage.setItem('schemaId', data.schemaId.toString());
      setSchema(data.payload);
      setLastViewedQuestionInCategory(
        data.payload.questions.map(() => ({ subCategoryIndex: 0, questionIndex: 0 })),
      );
    }
  }, [data]);

  useEffect(() => {
    if (debouncedSchema && status === 'success') {
      setShowSavedAlert(true);
      putMutate(debouncedSchema);
    }
  }, [debouncedSchema]);

  useEffect(() => {
    if (showSavedAlert) {
      const timeoutId = setTimeout(() => {
        setShowSavedAlert(false);
        setSavedStatus(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [showSavedAlert]);

  useEffect(() => {
    if (deliverStatus === 'success') {
      const schemaId = window.sessionStorage.getItem('schemaId');
      window.sessionStorage.removeItem('schemaId');
      navigate(`/tidligere-skjema/${schemaId}`);
    }
  }, [deliverStatus]);

  return (
    <Box display={'flex'} flexDirection={'column'} marginTop={5}>
      {status === 'error' && (
        <Alert severity='error'>Det oppstod en feil ved henting av skjema</Alert>
      )}
      {status === 'loading' && (
        <Grid
          container
          spacing={5}
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid item xs={2}>
            <Alert severity='info'>Henter miljøgjennomgangskjema</Alert>
          </Grid>
          <Grid item xs={2}>
            <CircularProgress size={100} />
          </Grid>
        </Grid>
      )}

      {status == 'success' &&
        (schema ? (
          <>
            <Grid container direction={'row'} spacing={2}>
              <CategoryList
                schema={schema}
                setActiveCategoryIndex={setActiveCategoryIndex}
                activeCategoryIndex={activeCategoryIndex}
              />
              <CategoryCard
                activeCategoryIndex={activeCategoryIndex}
                lastViewedQuestionInCategory={lastViewedQuestionInCategory}
                setLastViewedQuestionInCategory={setLastViewedQuestionInCategory}
                setActiveCategoryIndex={setActiveCategoryIndex}
              />
            </Grid>
            <Grid
              container
              direction={{ lg: 'row', md: 'column' }}
              justifyContent={'flex-end'}
              alignItems={'center'}
              spacing={1}
              sx={{ position: 'fixed', bottom: 0, right: 0, m: 2, width: 'auto' }}
              display={{ xs: 'none', md: 'flex' }}
            >
              <Grid item sx={{ width: { md: '100%', lg: 'auto' } }}>
                <Button size='medium' variant={'contained'} fullWidth onClick={handleOpenTutorial}>
                  Bruksanvisning
                </Button>
              </Grid>
              <Grid item sx={{ width: { md: '100%', lg: 'auto' } }}>
                <Button
                  onClick={() => (isSmallScreen ? ResetSchemaLogic(schema) : setOpenModal('reset'))}
                  variant='contained'
                  size='medium'
                  fullWidth
                >
                  Nullstill skjema
                </Button>
              </Grid>
              <Grid item sx={{ width: { md: '100%', lg: 'auto' } }}>
                <Button
                  onClick={() => (isSmallScreen ? deliverMutate(schema) : setOpenModal('deliver'))}
                  size='medium'
                  variant='contained'
                  fullWidth
                  endIcon={<FileDownloadDone />}
                >
                  Lever
                </Button>
              </Grid>
              {savedStatus && (
                <Grid
                  item
                  justifyContent={'flex-start'}
                  sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    m: 2,
                    display: 'inline-flex',
                  }}
                  display={{ xs: 'flex' }}
                >
                  <Grid item>
                    <Alert severity='success' sx={{ opacity: 0.7 }}>
                      Lagret
                    </Alert>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Tutorial handleOpen={openTutorial} handleClose={handleCloseTutorial} />
            <DeliverModal
              openModal={openModal === 'deliver'}
              handleCloseModal={() => setOpenModal('none')}
              deliver={() => deliverMutate(schema)}
              deliverStatus={deliverStatus}
            />
            <ResetSchemaModal
              openModal={openModal === 'reset'}
              handleCloseModal={() => setOpenModal('none')}
              reset={tryResetSchema}
              resetStatus={putStatus}
              onSuccess={() => {
                ResetSchemaLogic(schema);
              }}
            />
          </>
        ) : (
          <Alert severity='error'>Finner ikke skjema</Alert>
        ))}

      {status === 'success' && schema && (
        <Grid
          container
          direction={'column'}
          spacing={2}
          alignItems={'center'}
          display={{ xs: 'flex', md: 'none' }}
          mb={2}
        >
          <Grid item sx={{ minWidth: '33%' }}>
            <Button
              onClick={() => (isSmallScreen ? deliverMutate(schema) : setOpenModal('deliver'))}
              variant='contained'
              endIcon={<FileDownloadDone />}
              size='medium'
              fullWidth
            >
              Lever
            </Button>
          </Grid>
          <Grid item sx={{ minWidth: '33%' }}>
            <Button size='medium' variant={'contained'} onClick={handleOpenTutorial} fullWidth>
              Bruksanvisning
            </Button>
          </Grid>
          <Grid item sx={{ minWidth: '33%' }}>
            <Button
              onClick={() => (isSmallScreen ? ResetSchemaLogic(schema) : setOpenModal('reset'))}
              size='medium'
              variant='contained'
              fullWidth
            >
              Nullstill skjema
            </Button>
          </Grid>
          <DeliverModal
            openModal={openModal === 'deliver'}
            handleCloseModal={() => setOpenModal('none')}
            deliver={() => deliverMutate(schema)}
            deliverStatus={deliverStatus}
          />
          <ResetSchemaModal
            openModal={openModal === 'reset'}
            handleCloseModal={() => setOpenModal('none')}
            reset={tryResetSchema}
            resetStatus={putStatus}
            onSuccess={() => {
              ResetSchemaLogic(schema);
            }}
          />
          {savedStatus && (
            <Grid
              item
              justifyContent={'flex-start'}
              sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                m: 2,
                width: 'auto',
              }}
              display={{ xs: 'flex' }}
            >
              <Grid item>
                <Alert severity='success' sx={{ opacity: 0.7 }}>
                  Lagret
                </Alert>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};
