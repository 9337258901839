import React, { Dispatch, FunctionComponent, useEffect, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Grid, Modal, Typography } from '@mui/material';
import { FileDownloadDone, Cancel } from '@mui/icons-material/';

interface ResetSchemaModalProps {
  openModal: boolean;
  handleCloseModal: () => void;
  reset: Dispatch<void>;
  resetStatus: 'idle' | 'loading' | 'success' | 'error';
  onSuccess: () => void;
}

export const ResetSchemaModal: FunctionComponent<ResetSchemaModalProps> = ({
  openModal,
  handleCloseModal,
  reset,
  resetStatus,
  onSuccess,
}) => {
  const [confirmed, setConfirmed] = useState(false);

  const ModualStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'background.paper',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: 270,
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (resetStatus === 'success' && confirmed) {
      handleCloseModal();
      setConfirmed(false);
      onSuccess();
    }
  }, [resetStatus, confirmed]);

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={ModualStyle} display='flex' justifyContent='center' alignItems='center'>
        {resetStatus === 'loading' && (
          <Grid
            container
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={2}
          >
            <Grid item xs={6}>
              <Alert severity='info'>{confirmed ? 'Oppdaterer skjema' : 'Laster'}</Alert>
            </Grid>
            <Grid item xs={6}>
              <CircularProgress size={75} />
            </Grid>
          </Grid>
        )}
        {(resetStatus === 'idle' || resetStatus === 'success') && (
          <Grid container direction={'row'} spacing={4} justifyContent={'space-between'}>
            <Grid item xs={12}>
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Er du sikker på at du vil nullstille skjema?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle1' color='text.secondary' component='div'>
                Dersom du nullstiller skjema vil du miste alle svar. Dette kan ikke angres.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                onClick={() => {
                  setConfirmed(true);
                  reset();
                }}
              >
                Nullstill <FileDownloadDone />
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleCloseModal} variant='contained' color='error'>
                Avbryt <Cancel />
              </Button>
            </Grid>
          </Grid>
        )}
        {resetStatus === 'error' && (
          <Grid container spacing={6} justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={12}>
              <Alert severity='error'>Noe gikk galt ved nullstilling av skjema</Alert>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={handleCloseModal} variant='contained' color='error'>
                Avbryt <Cancel />
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};
