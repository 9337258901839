import React, { Dispatch, FunctionComponent, createContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@mui/material';
import AppRoutes from './AppRoutes';
import { Header, Layout } from 'components/core';
import { MuiTheme } from 'styles';
import { ISchema } from 'types';

const queryClient = new QueryClient();

interface ISchemaContext {
  schema: ISchema | undefined;
  setSchema: Dispatch<ISchema | undefined>;
}

export const SchemaContext = createContext<ISchemaContext>({
  schema: undefined,
  setSchema: () => undefined,
});

const App: FunctionComponent<Record<string, never>> = () => {
  const [schema, setSchema] = useState<ISchema | undefined>(undefined);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={MuiTheme}>
        <SchemaContext.Provider value={{ schema, setSchema }}>
          <Header />
          <Layout>
            <Routes>
              {AppRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
              })}
            </Routes>
          </Layout>
        </SchemaContext.Provider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
