import { Assessment, Assignment, EmojiPeople, TravelExplore } from '@mui/icons-material';
import React, { FunctionComponent } from 'react';

interface IQuestionIconProps {
  questionType: 1 | 2 | 3 | 4;
  fontSize?: 'inherit' | 'medium' | 'small' | 'large';
}

export const QuestionIcon: FunctionComponent<IQuestionIconProps> = ({
  questionType,
  fontSize = 'medium',
}) => {
  switch (questionType) {
    case 1:
      return <TravelExplore fontSize={fontSize} />;
    case 2:
      return <EmojiPeople fontSize={fontSize} />;
    case 3:
      return <Assignment fontSize={fontSize} />;
    case 4:
      return <Assessment fontSize={fontSize} />;
  }
};
