import React from 'react';
import { FunctionComponent } from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import Jordklode from 'images/jordklode.png';

export const InfoCard: FunctionComponent<Record<string, never>> = () => {
  return (
    <Card sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component='div' variant='h5'>
            Miljøgjennomgang
          </Typography>
          <Typography variant='subtitle1' color='text.secondary' component='div'>
            Det skal årlig utføres en miljøgjennomgang ved skolen/barnehagen. Miljøgjennomgangen gir
            unik innsikt i skolens eller barnehagens miljø- og klimaarbeid. Resultatene fra
            gjennomgangen gir oversikt over hvilke miljøområder det vil være lurt å legge fokuset på
            og bekrefter hvilke områder innen miljø og klima som allerede er ivaretatt.
            Gjennomgangen har til hensikt å gi konkrete ideer til aktiviteter og tiltak skolen eller
            barnehagen kan sette i gang.
          </Typography>
        </CardContent>
      </Box>
      <CardMedia
        component='img'
        sx={{ width: 151, height: 151, alignSelf: 'center' }}
        image={Jordklode}
        alt='Jordklode'
      />
    </Card>
  );
};
