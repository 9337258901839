import React, { FunctionComponent } from 'react';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { FileDownloadDone, Cancel } from '@mui/icons-material/';

interface PrintSchemaModalProps {
  openModal: boolean;
  handleCloseModal: () => void;
  schemaId: string | undefined;
  schemaName: string | undefined;
}

export const PrintSchemaModal: FunctionComponent<PrintSchemaModalProps> = ({
  openModal,
    handleCloseModal,
      schemaId,
  schemaName,
}) => {
  const ModualStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'background.paper',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: 270,
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

    const closeAndPrint = () => {
    handleCloseModal();
    setTimeout(() => {
      downloadPDF(schemaId, schemaName);
    }, 150);
  };

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={ModualStyle} display='flex' justifyContent='center' alignItems='center'>
        <Grid container direction={'row'} justifyContent={'space-between'} spacing={4}>
          <Grid item xs={12}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Last ned detaljert skjemaoppsummering
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1' color='text.secondary' component='div'>
              Last ned det fullførte skjemaet
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button variant='contained' onClick={closeAndPrint}>
              Last ned PDF <FileDownloadDone />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={handleCloseModal} variant='contained' color='error'>
              Avbryt <Cancel />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

async function downloadPDF(schemaId: string | undefined, schemaName: string | undefined) {
         try {
             const sessionId = sessionStorage.getItem("sessionId");
             const response = await fetch(`/schema/getpdf/${sessionId}/${schemaId}`);
            const blob = await response.blob();
             const url = window.URL.createObjectURL(blob);
             const a = document.createElement('a');
             a.href = url;
             a.download = schemaName + '.pdf';
             a.click();
            window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  }