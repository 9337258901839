import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { TextField } from '@mui/material/';
import { ISaveInputProps } from 'components/input';

export const TextInput: FunctionComponent<ISaveInputProps> = ({ saveAnswer, answer }) => {
  const [currentAnswer, setCurrentAnswer] = useState(answer);

  useEffect(() => {
    setCurrentAnswer(answer);
  }, [answer]);
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentAnswer(event.target.value);
    saveAnswer(event.target.value);
  };

  return (
    <TextField
      id='outlined-multiline-static'
      label='Ditt svar'
      multiline
      rows={4}
      placeholder=''
      onChange={handleChange}
      value={currentAnswer || ''}
      fullWidth
    />
  );
};
