import { createTheme } from '@mui/material';

export const MuiTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#414042',
          ':hover': {
            color: '#414042',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#95cf97',
      light: '#c9f7d9',
    },
    secondary: {
      main: '#414042',
    },
    error: {
      main: '#fc6d85',
    },
  },
});
