import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, Button, Grid, Modal, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import slide1 from 'images/side1.png';
import slide2 from 'images/side2.png';
import slide3 from 'images/side3.png';
import slide4 from 'images/side4.png';
import slide5 from 'images/side5.png';
import slideSave from 'images/slideSave.png';

const steps = [
  {
    label: '',
    description:
      'Velg hvilke kategorier dere vil fokusere på. Det er helt greit å hoppe over kategorier og spørsmål.',
    image: slide1,
  },
  {
    label: '',
    description: 'Forklarer hvordan type spørsmål det er og hva som må gjøres for å svare på det.',
    image: slide2,
  },
  {
    label: '',
    description: 'Svar på spørsmål.',
    image: slide3,
  },
  {
    label: '',
    description:
      'Hvis dere vil gi mer detaljerte svar kan dere legge til en kommentar. Det er også mulig å ikke svare på spørsmålet og bare legge igjen en kommentar.',
    image: slide4,
  },
  {
    label: '',
    description:
      'Skjemaet blir lagret automatisk. Hvis dere ikke er ferdig, kan dere lukke nettsiden og fortsette senere.',
    image: slideSave,
  },
  {
    label: '',
    description:
      'Når dere er ferdig med skjemaet trykker dere på lever. Det er ikke mulig å redigere svarene etter skjemaet er levert.',
    image: slide5,
  },
];

const ModualStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '95%',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  backgroundColor: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

interface TutorialProps {
  handleOpen: boolean;
  handleClose: () => void;
}

const Tutorial: FunctionComponent<TutorialProps> = ({ handleOpen, handleClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (handleOpen) {
      setActiveStep(0);
    }
  }, [handleOpen]);

  return (
    <div>
      <Modal sx={ModualStyle} open={handleOpen} BackdropProps={{ invisible: true }}>
        <Box sx={{ width: '80%', height: '100%' }}>
          <Grid container direction='column' justifyContent='center' alignItems='center'>
            <Box sx={{ width: '100%' }} justifyContent='center' alignItems='center'>
              <Stepper activeStep={activeStep}>
                {steps.map((step, index) => {
                  const stepProps: { completed?: boolean } = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={index} {...stepProps}>
                      <StepLabel>{step.label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length}
              {
                <React.Fragment>
                  <img
                    src={steps[activeStep].image}
                    alt='Step Image'
                    style={{
                      display: 'block',
                      margin: '20px auto',
                      textAlign: 'center',
                      maxWidth: '100%', // Set maxWidth to 100%
                      height: 'auto', // Maintain aspect ratio
                      maxHeight: '70vh',
                    }}
                  />
                  <Typography
                    variant='body1'
                    sx={{ mt: 2, mb: 1, fontSize: '0.9rem', textAlign: 'center' }}
                  >
                    {steps[activeStep].description}
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, maxWidth: '100%' }}>
                    <Button
                      color='inherit'
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ marginRight: '1vw', fontSize: '1rem' }}
                    >
                      Tilbake
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button
                      disabled={activeStep === steps.length - 1}
                      onClick={handleNext}
                      sx={{ marginRight: 'auto', marginLeft: '0', fontSize: '1rem' }}
                    >
                      Neste
                    </Button>
                  </Box>
                </React.Fragment>
              }
            </Box>
          </Grid>
          <Button
            onClick={handleClose}
            sx={{ position: 'absolute', top: '4%', right: '1%' }}
            color='secondary'
          >
            <Close />
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default Tutorial;
