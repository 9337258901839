import React, { Dispatch, FunctionComponent, useEffect, useState } from 'react';
import { Pagination, useMediaQuery, useTheme } from '@mui/material';
import { IActiveIndex } from 'pages/SchemaPage';
import { QuestionCategory } from '../../types';

interface INavigationBarProps {
  activeIndex: IActiveIndex;
    setActiveIndex: Dispatch<IActiveIndex>;
    category: QuestionCategory;
}

export const NavigationBar: FunctionComponent<INavigationBarProps> = ({
  activeIndex,
  setActiveIndex,
  category,
}) => {
  const [questionList, setQuestionList] = useState<
    { pagenumber: number; subCategoryIndex: number; questionIndex: number }[]
  >([]);

  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setActiveIndex({
      categoryIndex: activeIndex.categoryIndex,
      subCategoryIndex: questionList[value - 1].subCategoryIndex,
      questionIndex: questionList[value - 1].questionIndex,
    });
  };

  useEffect(() => {
    setQuestionList([]);
    category.subCategories.map((subCategory, subCategoryIndex) =>
      subCategory.items.map((_, questionIndex) => {
        setQuestionList((questionList) => [
          ...questionList,
          {
            pagenumber: questionList.length + 1,
            subCategoryIndex,
            questionIndex,
          },
        ]);
      }),
    );
  }, [category]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Pagination
      count={questionList.length}
      onChange={handleChange}
      page={
        questionList.findIndex(
          (listItem) =>
            listItem.subCategoryIndex === activeIndex.subCategoryIndex &&
            listItem.questionIndex === activeIndex.questionIndex,
        ) + 1
      }
      siblingCount={isSmallScreen ? 0 : 2}
      size={isSmallScreen ? 'medium' : 'large'}
      color='standard'
      sx={{
        display: 'flex', // Always displayed, size will adjust
        '& .MuiPaginationItem-root': {
          height: isSmallScreen ? 'auto' : '3.0rem',
          width: isSmallScreen ? 'auto' : '2.5rem',
          fontSize: isSmallScreen ? '1.5rem' : '1.2rem',
        },
        '& .MuiPaginationItem-ellipsis': {
          height: isSmallScreen ? 'auto' : '3.0rem',
          lineHeight: isSmallScreen ? 'auto' : '3.0rem',
        },
      }}
    ></Pagination>
  );
};
