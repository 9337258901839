import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';

interface IProgressBarProps {
  score: number;
  possible: number;
}

export const ProgressBar: FunctionComponent<IProgressBarProps> = ({ score, possible }) => {
  const progressBarValue = (score / possible) * 100;

  const [progress, setProgress] = useState<number>(0);

  // let red, green, blue;

  // if (progressBarValue === 0) {
  //   red = 255;
  //   green = 0;
  //   blue = 0;
  // } else if (progressBarValue < 50 && progressBarValue > 0) {
  //   red = Math.round(239 - 0.86 * progressBarValue);
  //   green = Math.round(4.6 * progressBarValue);
  //   blue = Math.round(133 - 0.66 * progressBarValue);
  // } else {
  //   red = Math.round(255 - 1.94 * (progressBarValue - 50));
  //   green = Math.round(255 + ((206 - 255) / 50) * (progressBarValue - 50));
  //   blue = Math.round((153 / 50) * (progressBarValue - 50));
  // }

  // const color = `rgb(${red}, ${green}, ${blue})`;
  const color = '#95cf97';

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= progressBarValue ? progressBarValue : prevProgress + 1,
      );
    }, 15);
    return () => {
      clearInterval(timer);
    };
  }, [progressBarValue]);

  return (
    <Box
      sx={{
        width: '40%',
        paddingTop: '1.05rem',
        paddingRight: '3%',
        display: { xs: 'none', lg: 'block' },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <LinearProgress
            variant='determinate'
            value={progress}
            sx={{
              height: '10px',
              borderRadius: '10px',
              border: '1px solid black',
              '& .MuiLinearProgress-bar': {
                backgroundColor: color,
              },
              backgroundColor: '#f1f1f1',
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography>{Math.round(progress)}%</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
