import React, { ReactElement, FunctionComponent } from 'react';
import { Container, useTheme } from '@mui/material';

interface ILayoutProps {
  children: ReactElement;
}

export const Layout: FunctionComponent<ILayoutProps> = (props) => {
  const theme = useTheme();

  return (
    <Container
      sx={{
        [theme.breakpoints.down('md')]: {
          maxWidth: 'sm',
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: '90%',
        },
        [theme.breakpoints.up('lg')]: {
          maxWidth: '90%',
        },
        [theme.breakpoints.up('xl')]: {
          maxWidth: 'xl',
        },
        height: '80vh',
      }}
    >
      {props.children}
    </Container>
  );
};
