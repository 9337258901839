import { ILandingPageContext } from 'types';

export const getValidation = async () => {
  const sessionId = window.sessionStorage.getItem('sessionId');
  if (!sessionId) {
    throw new Error('No session id found');
  }
  const response = await fetch(`schema/validate/${sessionId}`);
  if (response.status !== 200) {
    return null;
  }
  const validationResult: ILandingPageContext = await response.json();

  return validationResult;
};
