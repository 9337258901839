import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import { FileDownloadDone, Cancel } from '@mui/icons-material/';
import { useNavigate } from 'react-router-dom';
import { ViewTutorialModal } from './ViewTutorialModal';

interface SetNameModalProps {
  openModal: boolean;
  handleCloseModal: () => void;
}

export const SetNameModal: FunctionComponent<SetNameModalProps> = ({
  openModal,
  handleCloseModal,
}) => {
  const [schemaName, setSchemaName] = useState<string>(
    `Miljøgjennomgang ${new Date().getFullYear()}`,
  );
  const [openViewTutorialModal, setOpenViewTutorialModal] = useState(true);
  const handleCloseViewTutorialModal = () => {
    setOpenViewTutorialModal(false);
  };
  const navigate = useNavigate();

  const ModualStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'background.paper',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 400,
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleButtonClick = () => {
    if (schemaName === '') {
      const newName = `Miljøgjennomgang ${new Date().getFullYear()}`;
      setSchemaName(newName);
      navigate(`/skjema/${newName}`);
    } else {
      navigate(`/skjema/${schemaName}`);
    }
  };

  useEffect(() => {
    setOpenViewTutorialModal(true);
  }, [openModal]);

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={ModualStyle} display='flex' justifyContent='center'>
        <ViewTutorialModal
          openViewTutorialModal={openViewTutorialModal}
          handleOpenViewTutorialModal={handleCloseViewTutorialModal}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Navngi skjema
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1' color='text.secondary' component='div'>
              Du kan gi skjemaet et navn. Det kan f.eks. være hvilken avdeling, gruppe eller klasse
              som går gjennom skjemaet, eller hvilken dato dere har gjort det.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder='F.eks. Miljøgjennomgang 1'
              fullWidth
              variant='outlined'
              label='Navn'
              value={schemaName}
              onChange={(e) => setSchemaName(e.target.value)}
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Button variant='contained' onClick={handleButtonClick}>
                Lag Skjema <FileDownloadDone />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button onClick={handleCloseModal} variant='contained' color='error'>
                Avbryt <Cancel />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
