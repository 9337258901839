import React, { FunctionComponent } from 'react';
import { PodiumStep } from './PodiumStep';

interface IPodiumProps {
  topCategories: string[];
}

export const Podium: FunctionComponent<IPodiumProps> = ({ topCategories }) => {
  const podiumRanking = [topCategories[2], ...topCategories.slice(0, 2)];

  return (
    <div
      style={{
        display: 'grid',
        gridAutoFlow: 'column dense',
        gap: '0.1rem',
        marginTop: '2rem',
        justifyContent: 'center',
        justifyItems: 'center',
        alignContent: 'flex-end',
        alignItems: 'flex-end',
        borderBottom: '1px solid #e5e7eb',
        height: 250,
      }}
    >
      {podiumRanking.map((category, index) => (
        <PodiumStep
          key={category + index}
          categoryName={category ? category : 'ingen'}
          winner={index === 0 ? 3 : index}
        />
      ))}
    </div>
  );
};
