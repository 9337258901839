import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { SchemaContext } from 'App';
import { ISchemaResult, QuestionCategory, QuestionItem, QuestionSubCategory } from 'types';
import { getSchemaFromSchemaId } from 'util/requests';
import { useQueryClient } from 'react-query';
import { ArrowBack, ExpandMore, Print } from '@mui/icons-material';
import { PrintSchemaModal } from './PrintSchemaModal';
import { ImprovementBox, Podium, ProgressBar } from '.';

export const ViewFinishedSchemaPage: FunctionComponent<Record<string, never>> = () => {
  document.title = 'Detaljside';

  const queryClient = useQueryClient();
  const { schemaId } = useParams<{ schemaId: string }>();

  const { schema, setSchema } = useContext(SchemaContext);
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [data, setData] = useState<ISchemaResult | undefined>(undefined);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const getSchema = async (schemaId: string) => {
    setStatus('loading');
    try {
      const schemaResult = await queryClient.fetchQuery('getSchemaFromSchemaId', () =>
        getSchemaFromSchemaId(schemaId),
      );
      setData(schemaResult);
    } catch (_) {
      setStatus('error');
    }
    setStatus('success');
  };

  useEffect(() => {
    if (schemaId) {
      getSchema(schemaId);
    }
  }, [schemaId]);

  useEffect(() => {
    if (data) {
      setSchema(data.payload);
    }
  }, [data]);

  useEffect(() => {
    if (schema) {
      setCategoryScore([]);
      schema.questions.map((category: QuestionCategory, categoryIndex: number) => {
        let score = 0;
        let possible = 0;
        const categoryKey = category;
        category.subCategories.forEach(
          (subCategory: QuestionSubCategory, subCategoryIndex: number) => {
            subCategory.items.forEach((question: QuestionItem, questionsIndex: number) => {
              const answer =
                schema.answers[categoryIndex].subCategories[subCategoryIndex].items[questionsIndex]
                  .answer;
              if (answer) {
                possible++;
              }
              if (question.inputType === 'multipleChoice') {
                if (answer) {
                  score++;
                }
              }
              if (answer === 'Ja') {
                score++;
              }
            });
          },
        );
        setCategoryScore((prev) => [...prev, { score, possible, categoryKey, categoryIndex }]);
      });
    }
  }, [schema]);

  const [categoryScore, setCategoryScore] = useState<
    { score: number; possible: number; categoryKey: QuestionCategory }[]
  >([]);

  const improvementSuggestions = categoryScore
    .concat()
    .filter((category) => category.score < category.possible)
    .sort((a, b) => (a.score / a.possible > b.score / b.possible ? 1 : -1))
    .slice(0, 3)
    .map((category) => category.categoryKey.name);

  const bestCategories = categoryScore
    .concat()
    .filter((category) => category.possible > 0)
    .sort((a, b) => (a.score / a.possible < b.score / b.possible ? 1 : -1))
    .slice(0, 3)
    .map((category) => category.categoryKey.name);

  const topCategories = bestCategories.concat(Array(3 - bestCategories.length).fill(' '));

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      marginTop={5}
    >
      {status === 'error' && (
        <Alert severity='error'>Det oppstod en feil ved henting av skjema</Alert>
      )}
      {status === 'loading' && (
        <Grid
          container
          spacing={5}
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid item xs={2}>
            <Alert severity='info'>Henter miljøgjennomgangskjema</Alert>
          </Grid>
          <Grid item xs={2}>
            <CircularProgress size={100} />
          </Grid>
        </Grid>
      )}
      {status == 'success' &&
        (schema ? (
          <Container sx={{ marginTop: 5 }}>
            {schema ? (
              <>
                <Grid
                  container
                  item
                  xs={12}
                  alignItems={'center'}
                  direction={{ xs: 'column', md: 'row' }}
                  display={{ xs: 'grid', sm: 'flex', md: 'flex' }}
                  rowGap={2}
                  marginBottom={12}
                >
                  <Grid item xs={6}>
                    <Button
                      variant='contained'
                      component={Link}
                      to={'/tidligere-skjemaer'}
                      startIcon={<ArrowBack />}
                      sx={{ minWidth: 220 }}
                    >
                      Se leverte skjemaer
                    </Button>
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant={'contained'}
                      startIcon={<Print />}
                      onClick={() => setOpenModal(true)}
                      sx={{ minWidth: 220 }}
                    >
                      Last ned PDF
                    </Button>
                  </Grid>
                </Grid>
                <Grid container direction={'row'} justifyContent={'space-between'} spacing={2}>
                  <Grid item xs={12} md={6} minHeight={400}>
                    <Typography variant='h4' component='h4' textAlign={'center'}>
                      Topp 3
                    </Typography>
                    <Typography variant='body1' component='center'>
                      Bra jobba! Pallen viser kategoriene dere har jobbet mest med.
                    </Typography>

                    <Podium topCategories={topCategories} />
                  </Grid>
                  <Grid item xs={12} md={6} minHeight={400} justifyContent={'center'}>
                    <Typography variant='h4' component='h4' textAlign={'center'}>
                      Forbedringsforslag
                    </Typography>
                    <Typography variant='body1' component='center'>
                      Dette er kategoriene hvor dere har mest forbedringspotensiale fra de dere har
                      svart på. Kanskje dere kan jobbe litt ekstra med disse?
                    </Typography>
                    <ImprovementBox
                      suggestion1={improvementSuggestions[0] || undefined}
                      suggestion2={improvementSuggestions[1] || undefined}
                      suggestion3={improvementSuggestions[2] || undefined}
                    ></ImprovementBox>
                  </Grid>

                  <Grid item xs={12} marginBottom={5}>
                    {schema.questions.map((category: QuestionCategory, categoryIndex) => (
                      <Accordion key={categoryIndex}>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls={`${category.name}-content`}
                          id={`${category.name}-header`}
                        >
                          <Typography variant='h4' component='h4'>
                            {category.name}
                          </Typography>
                          <Typography
                            variant='h4'
                            component='h4'
                            sx={{ float: 'right', marginLeft: 'auto' }}
                          ></Typography>
                          {categoryScore[categoryIndex] &&
                            categoryScore[categoryIndex].possible > 0 && (
                              <ProgressBar
                                score={
                                  categoryScore[categoryIndex]
                                    ? categoryScore[categoryIndex].score
                                    : 0
                                }
                                possible={
                                  categoryScore[categoryIndex]
                                    ? categoryScore[categoryIndex].possible
                                    : 0
                                }
                              />
                            )}
                        </AccordionSummary>
                        <AccordionDetails key={categoryIndex + '-details'}>
                          {category.subCategories.map(
                            (subCategory: QuestionSubCategory, subCategoryIndex) => (
                              <div key={`${categoryIndex}${subCategoryIndex}`}>
                                {subCategory.name && (
                                  <Typography
                                    variant='h5'
                                    component='h5'
                                    key={`${categoryIndex}${subCategoryIndex}-typography`}
                                  >
                                    {subCategory.name}
                                  </Typography>
                                )}
                                {subCategory.items.map((question: QuestionItem, questionIndex) => (
                                  <div
                                    key={`${categoryIndex}${subCategoryIndex}${questionIndex}-question`}
                                  >
                                    <Typography fontWeight={'medium'}>
                                      {question.questionText}
                                    </Typography>
                                    <Typography>
                                      Svar:{' '}
                                      {schema.answers[categoryIndex].subCategories[subCategoryIndex]
                                        .items[questionIndex].answer
                                        ? schema.answers[categoryIndex].subCategories[
                                            subCategoryIndex
                                          ].items[questionIndex].answer
                                        : 'Ubesvart'}
                                    </Typography>
                                    <Typography marginBottom={3}>
                                      Kommentar:{' '}
                                      {
                                        schema.answers[categoryIndex].subCategories[
                                          subCategoryIndex
                                        ].items[questionIndex].comment
                                      }
                                    </Typography>
                                  </div>
                                ))}
                              </div>
                            ),
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Grid>

                  <PrintSchemaModal
                    openModal={openModal}
                    handleCloseModal={() => setOpenModal(false)}
                    schemaId={schemaId}
                    schemaName={schema.name}
                  />
                </Grid>
              </>
            ) : (
              <Alert severity='error'>Finner ikke skjema</Alert>
            )}
          </Container>
        ) : (
          <Alert severity='error'>Noe gikk galt ved henting av skjema</Alert>
        ))}
    </Box>
  );
};
