import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { getValidation } from 'util/requests';
import { useQuery } from 'react-query';
import { ILandingPageContext } from 'types';
import { SetNameModal } from './SetNameModal';
import { InfoCard, OrganizationCard } from '.';

const maxSessionIdLength = 25;

export const LandingPage: React.FunctionComponent<Record<string, never>> = () => {
  document.title = 'Miljøgjennomgang - Meny';

  const [enableQuery, setEnableQuery] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { status, data, isFetching } = useQuery<ILandingPageContext | null>(
    'getValidation',
    getValidation,
    {
      enabled: enableQuery,
      refetchOnWindowFocus: false,
    },
  );

  const [viewSetNameModal, setViewSetNameModal] = useState<boolean>(false);
  const BUTTON_MIN_WIDTH = 400;
  useEffect(() => {
    if (pathname && pathname.length > maxSessionIdLength) {
      window.sessionStorage.setItem('sessionId', pathname.slice(1));
    }
    setEnableQuery(true);
  }, [pathname]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const setAutoNameIfSmallScreen = () => {
    const newName = `Miljøgjennomgang ${new Date().getFullYear()}`;
    navigate(`/skjema/${newName}`);
  };
  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} marginTop={5}>
      {isFetching && (
        <Grid
          container
          spacing={5}
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid item xs={2}>
            <Alert severity='info'>Laster inn</Alert>
          </Grid>
          <Grid item xs={2}>
            <CircularProgress size={100} />
          </Grid>
        </Grid>
      )}
      {(status === 'error' || !data) && !isFetching && (
        <Grid
          container
          spacing={10}
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid item xs={2}>
            <Alert severity='error'>
              Det oppstod en feil ved henting av bruker. Har du prøvd å logge inn på nytt?
            </Alert>
          </Grid>
        </Grid>
      )}
      {status === 'success' && data && !isFetching && (
        <Grid
          container
          direction={'row'}
          marginTop={2}
          alignContent={'center'}
          alignItems={'center'}
          justifyContent={'center'}
          spacing={{ xs: 2, sm: 4, md: 6 }}
        >
          <Grid item xs={12}>
            <Typography variant='h2' component='h2' textAlign={'center'}>
              {`Velkommen ${data.validation.name}!`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InfoCard />
          </Grid>
          <Grid container item direction={'row'} justifyContent={'center'} spacing={2}>
            <Grid item>
              {data.unfinishedSchemaExists ? (
                <Button
                  variant='contained'
                  component={Link}
                  to='/skjema'
                  size='large'
                  sx={{ minWidth: BUTTON_MIN_WIDTH }}
                >
                  Fortsett på miljøgjennomgang
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() =>
                      isSmallScreen ? setAutoNameIfSmallScreen() : setViewSetNameModal(true)
                    }
                    variant='contained'
                    size='large'
                    sx={{ minWidth: BUTTON_MIN_WIDTH }}
                  >
                    Start ny miljøgjennomgang
                  </Button>
                  <SetNameModal
                    openModal={viewSetNameModal}
                    handleCloseModal={() => setViewSetNameModal(false)}
                  />
                </>
              )}
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                component={Link}
                to='/tidligere-skjemaer'
                size='large'
                sx={{ minWidth: BUTTON_MIN_WIDTH }}
              >
                Se tidligere miljøgjennomganger
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <OrganizationCard orgType={data.orgType} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
