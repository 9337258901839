import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import Tutorial from '../../components/core/Tutorial';
interface ViewTutorialModalProps {
  openViewTutorialModal: boolean;
  handleOpenViewTutorialModal: () => void;
}
export const ViewTutorialModal: FunctionComponent<ViewTutorialModalProps> = ({
  openViewTutorialModal,
  handleOpenViewTutorialModal,
}) => {
  const ModualStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'background.paper',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 400,
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(false);
  const handleOpenTutorial = () => {
    setOpen(true);
  };
  const handleCloseTutorial = () => {
    setOpen(false);
    handleOpenViewTutorialModal();
  };

  return (
    <Modal open={openViewTutorialModal}>
      <Box sx={ModualStyle} display='flex'>
        <Grid container spacing={1} direction='column' justifyContent='center' alignItems='center'>
          <Grid item>
            <Typography textAlign={'center'} variant={'h6'}>
              Vil du ha en gjennomgang av hvordan du svarer på spørreskjemaet?
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction='row'
            spacing={3}
            justifyContent='center'
            alignItems='center'
          >
            <Grid item>
              <Button size={'large'} variant={'contained'} onClick={handleOpenTutorial}>
                Ja
              </Button>
            </Grid>
            <Grid item>
              <Button size={'large'} variant={'contained'} onClick={handleOpenViewTutorialModal}>
                Nei
              </Button>
              <Tutorial handleOpen={open} handleClose={handleCloseTutorial} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
