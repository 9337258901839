import { IFinishedSchema } from 'types';

export const getFinishedSchemas = async () => {
  const sessionId = window.sessionStorage.getItem('sessionId');
  if (!sessionId) {
    throw new Error('No session id found');
  }
  const response = await fetch(`schema/getFinished/${sessionId}`);
  const finishedSchemas: IFinishedSchema[] = await response.json();
  return finishedSchemas;
};
