import { Card, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { FunctionComponent } from 'react';
import EmojiObjectsTwoToneIcon from '@mui/icons-material/EmojiObjectsTwoTone';

interface IImprovementBox {
  suggestion1: string | undefined;
  suggestion2: string | undefined;
  suggestion3: string | undefined;
}

export const ImprovementBox: FunctionComponent<IImprovementBox> = ({
  suggestion1,
  suggestion2,
  suggestion3,
}) => {
  return (
    <Grid
      container
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      minHeight={250}
    >
      <Grid item>
        <Card sx={{ p: 2, width: '100%' }}>
          <Stack direction={'column'} spacing={3} fontWeight={'bolder'} fontSize={18}>
            {suggestion1 ? (
              <div>
                <EmojiObjectsTwoToneIcon />
                {suggestion1}
              </div>
            ) : (
              <Typography align='center' variant='h6'>
                Alt ser bra ut! 😁👍
              </Typography>
            )}
            {suggestion2 && (
              <div>
                <EmojiObjectsTwoToneIcon />
                {suggestion2}
              </div>
            )}
            {suggestion3 && (
              <div>
                <EmojiObjectsTwoToneIcon />
                {suggestion3}
              </div>
            )}
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
};
