import React from 'react';
import { FunctionComponent } from 'react';
import { Box, Card, CardContent, List } from '@mui/material';
import { OrgCardListItem } from './OrgCardListItem';

interface IOrganizationCardProps {
  orgType: number;
}

export const OrganizationCard: FunctionComponent<IOrganizationCardProps> = ({ orgType }) => {
  return (
    <>
      <Card sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <List>
              {orgType == 1 ? (
                <>
                  <OrgCardListItem infoText='Gjennomfør denne sammen med barna, gjerne de eldste.' />
                  <OrgCardListItem
                    infoText='Poenget med gjennomgangen er ikke å få flest mulig smilefjes med en gang; mange
                  av punktene er ment for å gi nye ideer.'
                  />
                  <OrgCardListItem
                    infoText='Miljørådet i barnehagen bør bruke resultatene av gjennomgangen til å velge tema,
                  tiltak og aktiviteter til miljøhandlingsplane.'
                  />
                </>
              ) : (
                <>
                  {' '}
                  <OrgCardListItem
                    infoText='Miljøgjennomgangen skal utføres av miljørådet med elever, men kan også utføres
                  klassevis.'
                  />
                </>
              )}
              <OrgCardListItem
                infoText='Hver skole eller barnehage kan kun ha et aktivt miljøgjennomgangsskjema om gangen. Etter at et
                  miljøgjennomgangsskjema er fullført og levert, kan dere starte et nytt.'
              />
                      </List>
          </CardContent>
        </Box>
      </Card>
    </>
  );
};
