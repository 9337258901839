import React, { FunctionComponent, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Alert, Box, CircularProgress, Grid } from '@mui/material';
import { IFinishedSchema } from 'types';
import { DataTable } from './DataTable';
import { getFinishedSchemas } from 'util/requests';

export const FinishedSchemaPage: FunctionComponent<Record<string, never>> = () => {
  document.title = 'Oversikt';

  const queryClient = useQueryClient();
  const [getStatus, setGetStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');

  const [data, setData] = useState<IFinishedSchema[] | undefined>(undefined);

  const get = async () => {
    setGetStatus('loading');
    try {
      const posts = await queryClient.fetchQuery('getFinishedSchemas', getFinishedSchemas);
      setData(posts);
    } catch (_) {
      setGetStatus('error');
    }
    setGetStatus('success');
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} marginTop={5}>
      {getStatus === 'error' && (
        <Alert severity='error'>Det oppstod en feil ved henting av skjema</Alert>
      )}
      {getStatus === 'loading' && (
        <Grid
          container
          spacing={5}
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid item xs={2}>
            <Alert severity='info'>Laster inn</Alert>
          </Grid>
          <Grid item xs={2}>
            <CircularProgress size={100} />
          </Grid>
        </Grid>
      )}
          {getStatus === 'success' && data && (data.length > 0 ? (

          <DataTable finishedSchemas={data} />
        ) : (
          <Alert severity='info'>Ingen skjemaer er sendt inn enda</Alert>  
        ))}
    </Box>
  );
};
