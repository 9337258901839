import React, { useEffect } from 'react';
import { IInputProps } from 'components/input';
import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ListItemText, OutlinedInput } from '@mui/material';

export const MultipleChoiceInput: React.FunctionComponent<IInputProps> = ({
  saveAnswer,
    QuestionItem,
    AnswerItem
}) => {
  const ITEM_HEIGHT = 54;
  const ITEM_PADDING_TOP = 8;
  const VISIBLE_ITEMS_IN_LSIT = 4.5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * VISIBLE_ITEMS_IN_LSIT + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(typeof value === 'string' ? value.split(',') : value);

    saveAnswer(typeof value === 'string' ? value : value.join(','));
  };

  useEffect(() => {
      setSelectedOptions([]);
      if (AnswerItem.answer) {
      setSelectedOptions(AnswerItem.answer.split(','));
    }
  }, [AnswerItem]);

  return (
    <FormControl fullWidth>
      <InputLabel>Velg alternativ</InputLabel>
      <Select
        multiple
        value={selectedOptions}
        onChange={handleSelectChange}
        input={<OutlinedInput label='Velg alternativ' />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
        fullWidth
      >
        {QuestionItem.options?.map((option) => (
          <MenuItem key={option} value={option} style={{ whiteSpace: 'normal' }}>
            <Checkbox checked={selectedOptions.indexOf(option) > -1} />
            <ListItemText primary={option} style={{ whiteSpace: 'normal' }} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
