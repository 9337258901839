import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IFinishedSchema } from 'types';

interface IDataTableProps {
  finishedSchemas: IFinishedSchema[];
}

const columns: GridColDef[] = [
  { field: 'schemaId', headerName: 'schemaId' },
  { field: 'schemaName', headerName: 'Skjemanavn', flex: 1 },
  {
    field: 'deliveredTimestamp',
    headerName: 'Leveringsdato',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.split('T')[0].split('-').reverse().join('.');
    },
  },
  {
    field: 'viewMore',
    headerName: 'Se mer',
    sortable: false,
    flex: 0.5,
    renderCell: (params) => {
      const schemaId = params.api.getCellValue(params.id, 'schemaId');

      return (
        <Button
          variant={'contained'}
          fullWidth
          onClick={(e) => e.stopPropagation()}
          component={Link}
          to={`/tidligere-skjema/${schemaId}`}
        >
          Se mer
        </Button>
      );
    },
  },
];

export const DataTable: FunctionComponent<IDataTableProps> = ({ finishedSchemas }) => {
  const rows = finishedSchemas.map((schema, index) => {
    return {
      id: index,
      schemaId: schema.schemaId,
      schemaName: schema.schemaName,
      deliveredTimestamp: schema.deliveredTimestamp,
    };
  });

  return (
    <Grid container mb={3}>
      <Grid item xs={12} mb={5} mt={2}>
        <Typography variant='h2' component='h2' textAlign={'center'}>
          Tidligere skjemaer
        </Typography>
      </Grid>
      <DataGrid
        rows={rows}
        columns={columns.map((column) => ({
          ...column,
          disableColumnMenu: true,
        }))}
        columnVisibilityModel={{
          schemaId: false,
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          sorting: {
            sortModel: [
              {
                field: 'deliveredTimestamp',
                sort: 'desc',
              },
            ],
          },
        }}
        pageSizeOptions={[5, 10]}
      />
    </Grid>
  );
};
