import React, { FunctionComponent } from 'react';
import { Button } from '@mui/material/';

interface ICategoryButtonProps {
  name: string;
  setActiveCategoryIndex: () => void;
  active: boolean;
}

export const CategoryButton: FunctionComponent<ICategoryButtonProps> = ({
  name,
  setActiveCategoryIndex,
  active,
}) => {
  const categoryNameForButton = () => {
    if (name === 'Samfunnsansvar, forbruk og bærekraft') {
      return 'Samfunnsansvar';
    }
    if (name === 'Sunt kosthold og fysisk aktivitet') {
      return 'Kosthold og aktivitet';
    } else return name;
  };
  const BUTTON_MIN_WIDTH = 150;

  return (
    <Button
      variant={active ? 'contained' : 'outlined'}
      size='large'
      sx={{
        minWidth: BUTTON_MIN_WIDTH,
        padding: 1,
        marginBottom: 2,
      }}
      fullWidth
      onClick={() => setActiveCategoryIndex()}
    >
      {categoryNameForButton()}
    </Button>
  );
};
