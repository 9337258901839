import React, { FunctionComponent } from 'react';
import { ListItem, ListItemIcon, Typography } from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

interface IListItemProps {
  infoText: string;
}

export const OrgCardListItem: FunctionComponent<IListItemProps> = ({ infoText }) => {
  return (
    <ListItem>
      <ListItemIcon>
        <InfoRoundedIcon />
      </ListItemIcon>
      <Typography variant='subtitle1' color='text' component='div'>
        {infoText}
      </Typography>
    </ListItem>
  );
};
