import React, { Dispatch, FunctionComponent } from 'react';
import { TextInput, SmileyInput, YesNoInput, MultipleChoiceInput } from 'components/input';
import { AnswerItem, QuestionItem } from '../../types';

export interface IInputProps {
  QuestionItem: QuestionItem;
    AnswerItem: AnswerItem;
  saveAnswer: Dispatch<string>;
}

export interface ISaveInputProps {
  saveAnswer: Dispatch<string>;
    answer? : string | null;
}



export const InputPicker: FunctionComponent<IInputProps> = ({ QuestionItem, AnswerItem, saveAnswer }) => {
    switch (QuestionItem.inputType) {
      case 'multipleChoice':
          return <MultipleChoiceInput saveAnswer={saveAnswer} QuestionItem={QuestionItem} AnswerItem={AnswerItem} />;
      case 'tekst':
          return <TextInput saveAnswer={saveAnswer} answer={AnswerItem.answer} />;
      case 'ja/nei':
          return <YesNoInput saveAnswer={saveAnswer} answer={AnswerItem.answer} />;
      case 'smiley':
          return <SmileyInput saveAnswer={saveAnswer} answer={AnswerItem.answer} />;
    default:
      return <YesNoInput saveAnswer={saveAnswer} answer={AnswerItem.answer} />;
  }
};

