import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Alert, Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import { ArrowCircleLeft, ArrowCircleRight, Comment } from '@mui/icons-material/';
import { InputPicker } from 'components/input';
import { NavigationBar } from 'pages/SchemaPage';
import { SchemaContext } from 'App';
import Svanemerke from 'images/svanemerke.png';
import Fairtrade from 'images/fairtrade.png';
import { QuestionIconExplanation } from 'components/core/QuestionIconExplanation';

interface ICategoryCardProps {
  activeCategoryIndex: number;
  lastViewedQuestionInCategory: { subCategoryIndex: number; questionIndex: number }[];
  setLastViewedQuestionInCategory: Dispatch<
    SetStateAction<{ subCategoryIndex: number; questionIndex: number }[]>
  >;
  setActiveCategoryIndex: (index: number) => void;
}
export interface IActiveIndex {
  categoryIndex: number;
  subCategoryIndex: number;
  questionIndex: number;
}

const activeIndexWithSymbols = {
  categoryIndex: 6,
  subCategoryIndex: 0,
  questionIndex: 1,
};

export const CategoryCard: FunctionComponent<ICategoryCardProps> = ({
  activeCategoryIndex,
  lastViewedQuestionInCategory,
  setLastViewedQuestionInCategory,
  setActiveCategoryIndex,
}) => {
  const { schema, setSchema } = useContext(SchemaContext);

  if (!schema) return <Alert severity='error'>Noe gikk galt ved henting av skjema</Alert>;

  const [showComment, setShowComment] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<IActiveIndex>({
    categoryIndex: activeCategoryIndex,
    subCategoryIndex: 0,
    questionIndex: 0,
  });

  const activeObjects = {
    category: schema.questions[activeIndex.categoryIndex],
    subCategory:
      schema.questions[activeIndex.categoryIndex].subCategories[activeIndex.subCategoryIndex],
    question:
      schema.questions[activeIndex.categoryIndex].subCategories[activeIndex.subCategoryIndex].items[
        activeIndex.questionIndex
      ],
    answer:
      schema.answers[activeIndex.categoryIndex].subCategories[activeIndex.subCategoryIndex].items[
        activeIndex.questionIndex
      ],
  };
  const maxLengths = {
    category: schema.questions.length - 1,
    subCategory: schema.questions[activeIndex.categoryIndex].subCategories.length - 1,
    question:
      schema.questions[activeIndex.categoryIndex].subCategories[activeIndex.subCategoryIndex].items
        .length - 1,
  };

  useEffect(() => {
    switchCategory();
  }, [activeCategoryIndex]);

  useEffect(() => {
    const newLastViewedQuestionInCategory = lastViewedQuestionInCategory.map((item, index) => {
      if (index == activeIndex.categoryIndex) {
        return {
          subCategoryIndex: activeIndex.subCategoryIndex,
          questionIndex: activeIndex.questionIndex,
        };
      } else {
        return item;
      }
    });
    setLastViewedQuestionInCategory(newLastViewedQuestionInCategory);
  }, [activeIndex]);

  const saveAnswer = (answer: string) => {
    if (schema) {
      /*
      if (schema.categories[activeCategoryIndex].subCategories == undefined) {
        return;
      }
      if (
        schema.categories[activeCategoryIndex].subCategories[activeIndex.subCategoryIndex]
          .questions == undefined
      ) {
        return;
      }
      */

      schema.answers[activeIndex.categoryIndex].subCategories[activeIndex.subCategoryIndex].items[
        activeIndex.questionIndex
      ].answer = answer;
      setSchema({ ...schema });
    }
  };

  const saveComment = (comment: string) => {
    if (schema) {
      schema.answers[activeIndex.categoryIndex].subCategories[activeIndex.subCategoryIndex].items[
        activeIndex.questionIndex
      ].comment = comment;
      setSchema({ ...schema });
    }
  };

  const switchCategory = () => {
    /*
    if (schema.categories[activeCategoryIndex].subCategories[0] == undefined) {
      setActiveIndex({
        categoryIndex: 0,
        subCategoryIndex: 0,
        questionIndex: 0,
      });
      return;
    }

    if (
      schema.categories[activeCategoryIndex].subCategories[activeIndex.subCategoryIndex]
        .questions[0] == undefined
    ) {
      setActiveIndex({
        categoryIndex: 0,
        subCategoryIndex: 0,
        questionIndex: 0,
      });
      return;
    }*/

    const lastViewed = lastViewedQuestionInCategory[activeCategoryIndex];

    if (lastViewed) {
      setActiveIndex({
        categoryIndex: activeCategoryIndex,
        subCategoryIndex: lastViewed.subCategoryIndex,
        questionIndex: lastViewed.questionIndex,
      });
    } else {
      setActiveIndex({
        categoryIndex: 0,
        subCategoryIndex: 0,
        questionIndex: 0,
      });
    }
    setShowComment(false);
  };

  const nextQuestion = () => {
    if (activeIndex.questionIndex === maxLengths.question) {
      if (activeIndex.subCategoryIndex === maxLengths.subCategory) {
        navigateCategory('next');
      } else {
        setActiveIndex({
          categoryIndex: activeIndex.categoryIndex,
          subCategoryIndex: activeIndex.subCategoryIndex + 1,
          questionIndex: 0, // Reset question index when moving to the next subCategory
        });
      }
    } else {
      setActiveIndex({
        categoryIndex: activeIndex.categoryIndex,
        subCategoryIndex: activeIndex.subCategoryIndex,
        questionIndex: activeIndex.questionIndex + 1,
      });
    }
  };

  const previousQuestion = () => {
    if (activeIndex.questionIndex === 0) {
      if (activeIndex.subCategoryIndex === 0) {
        navigateCategory('previous');
      } else {
        setActiveIndex({
          categoryIndex: activeIndex.categoryIndex,
          subCategoryIndex: activeIndex.subCategoryIndex - 1,
          questionIndex:
            schema.questions[activeIndex.categoryIndex].subCategories[
              activeIndex.subCategoryIndex - 1
            ].items.length - 1,
        });
      }
    } else {
      setActiveIndex({
        categoryIndex: activeIndex.categoryIndex,
        subCategoryIndex: activeIndex.subCategoryIndex,
        questionIndex: activeIndex.questionIndex - 1,
      });
    }
  };

  const navigateCategory = (direction: string) => {
    const newIndex = direction === 'next' ? activeCategoryIndex + 1 : activeCategoryIndex - 1;
    if (newIndex >= 0 && newIndex <= maxLengths.category) {
      setActiveIndex({
        categoryIndex: newIndex,
        subCategoryIndex: 0,
        questionIndex: 0,
      });
      setActiveCategoryIndex(newIndex);
    }
  };

  const isFirstCategoryQuestion =
    activeCategoryIndex == 0 && activeIndex.subCategoryIndex == 0 && activeIndex.questionIndex == 0;
  const isLastCategoryQuestion =
    activeIndex.subCategoryIndex == maxLengths.subCategory &&
    activeIndex.questionIndex == maxLengths.question &&
    activeCategoryIndex == maxLengths.category;

  return (
    <>
      <Grid item xs={12} md={8} mt={{ xs: 0, md: 8 }}>
        <Grid container direction={'column'} alignItems={'center'} spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h4' fontWeight={'400'} component='h4' textAlign={'center'}>
              {activeObjects.subCategory.name
                ? activeObjects.subCategory.name
                : activeObjects.category.name}
            </Typography>
          </Grid>
          <Grid item xs={12} display={{ xs: 'block', md: 'none' }}>
            {activeObjects.question.questionType && (
              <QuestionIconExplanation questionType={activeObjects.question.questionType} />
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant='h5'
              component='h5'
              textAlign={'center'}
              mr={2}
              ml={2}
              minHeight={100}
            >
              {activeObjects.question.questionText}
              {activeIndex.categoryIndex == activeIndexWithSymbols.categoryIndex &&
                activeIndex.subCategoryIndex == activeIndexWithSymbols.subCategoryIndex &&
                activeIndex.questionIndex == activeIndexWithSymbols.questionIndex && (
                  <>
                    <img src={Svanemerke} width={60} height={60} alt='Svanemerket' />
                    <img
                      src={Fairtrade}
                      width={50}
                      height={50}
                      style={{ marginLeft: '10px' }}
                      alt='Fairtrade'
                    />
                  </>
                )}
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            flexDirection={'row'}
            justifyContent={'space-evenly'}
            alignItems={'center'}
          >
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <IconButton
                disabled={isFirstCategoryQuestion}
                sx={{
                  opacity: isFirstCategoryQuestion ? '0%' : '100%',
                }}
                onClick={previousQuestion}
                color='secondary'
              >
                <ArrowCircleLeft sx={{ fontSize: '80px' }} />
              </IconButton>
              <Typography
                sx={{
                  opacity: isFirstCategoryQuestion
                    ? '0%'
                    : activeIndex.subCategoryIndex == 0 && activeIndex.questionIndex == 0
                    ? '100%'
                    : '0%',
                }}
              >
                Forrige kategori
              </Typography>
            </Grid>
            <Grid container item xs={5} height={150} alignItems={'center'}>
              <Grid item xs={12}>
                <InputPicker
                  QuestionItem={activeObjects.question}
                  AnswerItem={
                    schema.answers[activeIndex.categoryIndex].subCategories[
                      activeIndex.subCategoryIndex
                    ].items[activeIndex.questionIndex]
                  }
                  saveAnswer={saveAnswer}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <IconButton
                sx={{
                  opacity: isLastCategoryQuestion ? '0%' : '100%',
                }}
                disabled={isLastCategoryQuestion}
                onClick={nextQuestion}
                color='secondary'
              >
                <ArrowCircleRight sx={{ fontSize: '80px' }} />
              </IconButton>
              <Typography
                sx={{
                  opacity:
                    activeCategoryIndex == maxLengths.category
                      ? '0%'
                      : activeIndex.subCategoryIndex == maxLengths.subCategory &&
                        activeIndex.questionIndex == maxLengths.question
                      ? '100%'
                      : '0%',
                }}
              >
                Neste kategori
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} zIndex={2}>
            <Button
              onClick={() => setShowComment(!showComment)}
              startIcon={<Comment />}
              variant={activeObjects.answer.comment ? 'contained' : 'outlined'}
              size='large'
            >
              {showComment
                ? 'Skjul kommentar'
                : activeObjects.answer.comment
                ? 'Se Kommentar'
                : 'Kommenter spørsmål'}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <NavigationBar
              category={activeObjects.category}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            />
          </Grid>

          <Grid
            item
            xs={12}
            zIndex={2}
            mb={{ xs: 1, md: 5 }}
            position={'relative'}
            width={'55%'}
            height={125}
          >
            {showComment && (
              <TextField
                hidden={!showComment}
                id='outlined-multiline-static'
                multiline
                fullWidth
                rows={5}
                placeholder=''
                onChange={(e) => saveComment(e.target.value)}
                value={activeObjects.answer.comment || ''}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} display={{ xs: 'none', md: 'block' }}>
        {activeObjects.question.questionType && (
          <QuestionIconExplanation questionType={activeObjects.question.questionType} />
        )}
      </Grid>
    </>
  );
};
