import React, { FunctionComponent, MouseEvent, useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { ISaveInputProps } from 'components/input';

export const YesNoInput: FunctionComponent<ISaveInputProps> = ({ saveAnswer, answer }) => {
    const [currentAnswer, setCurrentAnswer] = useState(answer);

  useEffect(() => {
    setCurrentAnswer(answer);
  }, [answer]);
  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (event.currentTarget.textContent) {

        if (currentAnswer === event.currentTarget.textContent) {
            setCurrentAnswer('');
        }
        else {
            setCurrentAnswer(event.currentTarget.textContent);
        }
      saveAnswer(event.currentTarget.textContent);
    }
  };
  const BUTTON_WIDTH = 130;
  return (
    <Stack direction={'row'} justifyContent={'center'} spacing={2} height={100}>
      <Button
        variant={currentAnswer == 'Ja' ? 'contained' : 'outlined'}
        onClick={handleButtonClick}
        size='large'
        fullWidth
        sx={{ maxWidth: BUTTON_WIDTH, fontSize: '1.2rem' }}
      >
        Ja
      </Button>
      <Button
        variant={currentAnswer == 'Nei' ? 'contained' : 'outlined'}
        onClick={handleButtonClick}
        size='large'
        fullWidth
        sx={{ maxWidth: BUTTON_WIDTH, fontSize: '1.2rem' }}
      >
        Nei
      </Button>
    </Stack>
  );
};
