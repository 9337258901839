import React, { FunctionComponent } from 'react';
import { Grid, ListItemText, MenuItem, Select, Stack } from '@mui/material';
import { CategoryButton } from 'pages/SchemaPage/';
import { ISchema } from 'types';

interface ICategoryListProps {
  schema: ISchema;
  setActiveCategoryIndex: (activeCategoryIndex: number) => void;
  activeCategoryIndex: number;
}

export const CategoryList: FunctionComponent<ICategoryListProps> = ({
  schema,
  setActiveCategoryIndex,
  activeCategoryIndex,
}) => {
  return (
    <Grid item xs={12} md={2}>
      <Select
        fullWidth
        value={activeCategoryIndex}
        onChange={(event) => setActiveCategoryIndex(Number(event.target.value))}
        sx={{
          margin: 1,
          display: { xs: 'block', md: 'none' },
        }}
      >
        {schema.questions.map((category, index) => (
          <MenuItem key={index} value={index}>
            <ListItemText primary={category.name} />
          </MenuItem>
        ))}
      </Select>

      <Stack display={{ xs: 'none', md: 'block' }}>
        {schema.questions.map((category, index) => (
          <CategoryButton
            key={index}
            name={category.name}
            setActiveCategoryIndex={() => setActiveCategoryIndex(index)}
            active={index === activeCategoryIndex}
          />
        ))}
      </Stack>
    </Grid>
  );
};
