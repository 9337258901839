import React, { FunctionComponent } from 'react';
import { QuestionIcon } from 'components/core';
import { Grid, Typography } from '@mui/material';

export interface IQuestionIconExplanationProps {
  questionType: 1 | 2 | 3 | 4;
}

export const QuestionIconExplanation: FunctionComponent<IQuestionIconExplanationProps> = ({
  questionType,
}) => {
  switch (questionType) {
    case 1:
      return (
        <Grid container direction={{ xs: 'row', md: 'column' }}>
          <Grid item xs={1} md={12}>
            <QuestionIcon questionType={questionType} fontSize='large' />
          </Grid>
          <Grid item xs={11} md={12}>
            <Typography variant='subtitle2'>
              Dere kan selv finne svaret ved å undersøke nøye rundt på skolen.
            </Typography>
          </Grid>
        </Grid>
      );
    case 2:
      return (
        <Grid container direction={{ xs: 'row', md: 'column' }}>
          <Grid item xs={1} md={12}>
            <QuestionIcon questionType={questionType} fontSize='large' />
          </Grid>
          <Grid item xs={11} md={12}>
            <Typography variant='subtitle2'>
              Dere kan spørre en voksen for å finne svaret.
            </Typography>
          </Grid>
        </Grid>
      );
    case 3:
      return (
        <Grid container direction={{ xs: 'row', md: 'column' }}>
          <Grid item xs={1} md={12}>
            <QuestionIcon questionType={questionType} fontSize='large' />
          </Grid>
          <Grid item xs={11} md={12}>
            <Typography variant='subtitle2'>
              Her trengs det litt ekstra undersøkelse som for eksempel å lage spørsmål til medelever
              eller en liten undersøkelse.
            </Typography>
          </Grid>
        </Grid>
      );
    case 4:
      return (
        <Grid container direction={{ xs: 'row', md: 'column' }}>
          <Grid item xs={1} md={12}>
            <QuestionIcon questionType={questionType} fontSize='large' />
          </Grid>
          <Grid item xs={11} md={12}>
            <Typography variant='subtitle2'>
              Dette spørsmålet kan være interessant for å følge med på utvikling over tid.
            </Typography>
          </Grid>
        </Grid>
      );
  }
};
