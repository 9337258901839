import React, { FunctionComponent } from 'react';
import { Box, Button, AppBar, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import { Home } from '@mui/icons-material/';

export const Header: FunctionComponent = () => {
  return (
    <Box>
      <AppBar position='static' color='transparent'>
        <Toolbar variant='dense'>
          <Button component={Link} to='/'>
            <Home></Home>
            Hjem
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
