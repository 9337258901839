import React, { FunctionComponent } from 'react';
import { motion } from 'framer-motion';
import { Grid } from '@mui/material';

interface IPodiumStepProps {
  categoryName: string;
  winner: number;
}

export const PodiumStep: FunctionComponent<IPodiumStepProps> = ({ categoryName, winner }) => {
  const categoryNameForPodium = () => {
    if (categoryName === 'Samfunnsansvar, forbruk og bærekraft') {
      return 'Samfunnsansvar';
    }
    if (categoryName === 'Sunt kosthold og fysisk aktivitet') {
      return 'Kosthold og aktivitet';
    } else return categoryName;
  };

  // const medalForWinners = ['🥇', '🥈', '🥉'];

  return (
    <Grid container direction={'column'} alignItems='center'>
      <Grid
        item
        sx={{
          color: 'black',
          width: '8.0rem',
          textAlign: 'center',
          fontSize: 15,
          fontWeight: 'bold',
        }}
      >
        {categoryNameForPodium()}
      </Grid>
      <Grid item>
        <motion.div
          style={{
            width: '8rem',
            placeContent: 'center',
            display: 'flex',
            borderTopLeftRadius: '.5rem',
            borderTopRightRadius: '.5rem',
            borderColor: '#414042',
            backgroundColor: '#19a6c3',
            marginBottom: -1,
            filter: `opacity(${1 - (winner / 3) * 0.5})`,
          }}
          initial='hidden'
          animate='visible'
          variants={{
            visible: {
              height: 250 - winner * 50,
              opacity: 1,
              transition: {
                delay: 1,
                duration: 2,
                ease: 'backInOut',
              },
            },
            hidden: { opacity: 0, height: 0 },
          }}
        >
          <Grid container direction={'column'} alignItems='center' sx={{ alignSelf: 'flex-end' }}>
            <Grid item sx={{ fontSize: '40px', color: 'white', fontWeight: 'bolder' }}>
              {winner}
            </Grid>
          </Grid>
        </motion.div>
      </Grid>
    </Grid>
  );
};
