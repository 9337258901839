import { ISchema } from 'types';

export const putSchema = async (schema: ISchema, finished = false) => {
  const sessionId = window.sessionStorage.getItem('sessionId');
  if (!sessionId) {
    throw new Error('No session id found');
  }
  const schemaId = window.sessionStorage.getItem('schemaId');
  if (!schemaId) {
    throw new Error('No schema id found');
  }


  const response = await fetch(`schema/${finished ? 'deliver/' : ''}${sessionId}/${schemaId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Charset': 'utf-8',
      },
      body: JSON.stringify(schema.answers),
  });
  if (!response.ok) {
    throw new Error(`Failed to update schema: ${response.statusText}`);
  } else {
    return true;
  }
};
