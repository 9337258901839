import React, { FunctionComponent, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { Stack } from '@mui/material';
import SmileyImageYes from 'images/smiley-happy.png';
import SmileyImageNo from 'images/smiley-frown.png';
import { ISaveInputProps } from './InputPicker';

export const SmileyInput: FunctionComponent<ISaveInputProps> = ({ saveAnswer, answer }) => {
  const [currentAnswer, setCurrentAnswer] = useState(answer);
  useEffect(() => {
      setCurrentAnswer(answer);
  }, [answer]);

    const handleImageClick = (value: string) => {
        const newAnswer = currentAnswer === value ? '' : value;
        setCurrentAnswer(newAnswer);
        saveAnswer(newAnswer);
    };

    const imageStylesSmiley: React.CSSProperties = {
    width: '120px',
    height: '120px',
  };
  const imageStylesSmileyNotSelected: React.CSSProperties = {
    width: '120px',
    height: '120px',
    filter: 'grayscale(100%)',
  };

  return (
    <Stack
      direction={'row'}
      sx={{ width: '100%', height: '80%' }}
      justifyContent={'center'}
      spacing={2}
    >
      <IconButton size='small' onClick={() => handleImageClick('Ja')}>
        <img
          src={SmileyImageYes}
          alt='Ja'
          style={currentAnswer === 'Nei' ? imageStylesSmileyNotSelected : imageStylesSmiley}
        />
      </IconButton>
      <IconButton size='small' onClick={() => handleImageClick('Nei')}>
        <img
          src={SmileyImageNo}
          alt='Nei'
          style={currentAnswer === 'Ja' ? imageStylesSmileyNotSelected : imageStylesSmiley}
        />
      </IconButton>
    </Stack>
  );
};
