import { ISchema, ISchemaResult } from 'types';

export const getSchemaFromSchemaId = async (schemaId: string) => {
  const sessionId = window.sessionStorage.getItem('sessionId');
  if (!sessionId) {
    throw new Error('No session id found');
  }
  const response = await fetch(`schema/get/${sessionId}/${schemaId}`);
  const schemaResult: { schemaId: string; name: string; questions: string; answers: string } = await response.json();

  const schema: ISchema = {
    name: schemaResult.name,
    questions: JSON.parse(schemaResult.questions),
    answers: JSON.parse(schemaResult.answers),
  };


  return {
      schemaId: schemaResult.schemaId,
      payload: schema,
  } as ISchemaResult;
};
