import React from 'react';
import { LandingPage } from 'pages/LandingPage';
import { SchemaPage } from 'pages/SchemaPage';
import { FinishedSchemaPage, ViewFinishedSchemaPage } from 'pages/FinishedSchemaPage';

const AppRoutes = [
  {
    index: true,
    path: '/*',
    element: <LandingPage />,
  },
  {
    index: true,
    path: '/skjema/:name',
    element: <SchemaPage />,
  },
  {
    index: true,
    path: '/skjema',
    element: <SchemaPage />,
  },
  {
    index: true,
    path: '/tidligere-skjemaer',
    element: <FinishedSchemaPage />,
  },
  {
    index: true,
    path: '/tidligere-skjema/:schemaId',
    element: <ViewFinishedSchemaPage />,
  },
];

export default AppRoutes;
