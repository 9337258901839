import React, { Dispatch, FunctionComponent } from 'react';
import { Alert, Box, Button, CircularProgress, Grid, Modal, Typography } from '@mui/material';
import { FileDownloadDone, Cancel } from '@mui/icons-material/';

interface DeliverModalProps {
  openModal: boolean;
  handleCloseModal: () => void;
  deliver: Dispatch<void>;
  deliverStatus: 'idle' | 'loading' | 'success' | 'error';
}

export const DeliverModal: FunctionComponent<DeliverModalProps> = ({
  openModal,
  handleCloseModal,
  deliver,
  deliverStatus,
}) => {
  const ModualStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'background.paper',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: 270,
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={ModualStyle} display='flex' justifyContent='center' alignItems='center'>
        {deliverStatus === 'loading' && (
          <Grid
            container
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={2}
          >
            <Grid item xs={6}>
              <Alert severity='info'>Leverer skjema</Alert>
            </Grid>
            <Grid item xs={6}>
              <CircularProgress size={75} />
            </Grid>
          </Grid>
        )}
        {deliverStatus === 'idle' && (
          <Grid container direction={'row'} spacing={4} justifyContent={'space-between'}>
            <Grid item xs={12}>
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Er du sikker på at du vil levere?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle1' color='text.secondary' component='div'>
                Når du leverer skjema vil du ikke ha mulighet til å redigere på svarene dine.
              </Typography>
            </Grid>
            <Grid item>
              <Button variant='contained' onClick={() => deliver()}>
                Lever <FileDownloadDone />
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleCloseModal} variant='contained' color='error'>
                Avbryt <Cancel />
              </Button>
            </Grid>
          </Grid>
        )}
        {deliverStatus === 'error' && (
          <Grid container spacing={6} justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={12}>
              <Alert severity='error'>Noe gikk galt ved levering av skjema</Alert>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={handleCloseModal} variant='contained' color='error'>
                Avbryt <Cancel />
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};
